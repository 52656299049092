/**
 * Edieur Quill js avec un associé à un custom element
 * 
 * @author eliseekn (eliseekn[at]gmail[dot]com)
 */
class TextEditor extends HTMLElement {
    constructor() {
        super()

        let form = document.querySelector(this.getAttribute('form'))

        form.addEventListener('submit', e => {
            e.preventDefault()
            
            let formData = new FormData(form)
            formData.append('editor', this.editor.root.innerHTML)

            fetch(form.action, {
                method: 'POST',
                body: formData
            })
                .then(() => window.location.reload())
        })
    }

    connectedCallback() {
        this.innerHTML = `<div id="${this.getAttribute('id')}">${this.getAttribute('content')}</div>`
        
        //initialize editor
        this.editor = new Quill('#' + this.getAttribute('id'), {
            modules: {toolbar: `#${this.getAttribute('id')}-toolbar`},
            theme: 'snow'
        })

        //customize editor
        document.querySelectorAll('.ql-editor').forEach(el => {
            el.setAttribute('style', 'font-size: 1rem')
        })

        document.querySelector('#' + this.getAttribute('id')).classList.add('border-0')
    }
}

export default TextEditor
